@import "~rivals/styles/breakpoints.scss";
@import "~rivals/styles/colors.scss";
@import "~rivals/styles/mixins.scss";
@import "~rivals/styles/typography.scss";
@import "~rivals/styles/variables.scss";

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: $spacing-36 auto;
}

.container {
  display: flex;
  flex-direction: column;
  max-width: $body-width;
  row-gap: $spacing-36;
  @include sizes-ending-with($screen-lg-mobile-max) {
    max-width: calc(100% - $spacing-24);
  }
  @include sizes-ending-with($screen-lg-mobile-max) {
    row-gap: $spacing-24;
  }

  .teamSelector {
    :global {
      div.ant-select-selector {
        @include sizes-starting-with($screen-lg-mobile-max) {
          max-width: 100%;
        }
      }
    }
  }
}

.header {
  padding-bottom: $spacing-12;
  @include font-extended-headline-H1;
  @include sizes-ending-with($screen-lg-mobile-max) {
    @include font-primary-bold-SB2;
  }
}

.sectionHeader {
  @include font-primary-bold-SB2;
  @include sizes-ending-with($screen-lg-mobile-max) {
    @include font-primary-bold-SB3;
  }
}

@mixin section {
  background-color: $sand;
  border: 1px solid $bone-50;
  display: flex;
  flex-direction: column;
  padding: $spacing-24 $spacing-12;
  row-gap: $spacing-24;

  &.primary {
    background-color: $bone-10;
  }

  &.subsection {
    background-color: $bone-10;
    padding: $spacing-12;
  }
}

.section {
  @include section;
}

.borderlessSection {
  @include section;
  border: none;
  padding: $spacing-12;
}

.title {
  @include font-extended-extrabold-A3;
  align-items: center;
  background-color: $eye-black;
  color: $bone;
  column-gap: $spacing-8;
  display: flex;
  padding: $spacing-8;
}

.checkboxContainer {
  align-items: center;
  column-gap: $spacing-36;
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-template-rows: auto;
  row-gap: $spacing-12;

  .label {
    @include font-primary-medium-S4;
    text-align: right;
  }
}

.checkbox {
  .checkboxLabel {
    input {
      margin-right: $spacing-8;
    }
  }
}

.description {
  @include font-primary-body-B4;
  align-items: center;
  column-gap: $spacing-12;
  display: flex;
  line-height: 120%;
}

.headerButtonContainer {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.headerButton {
  @include font-primary-bold-SB5;
  padding: 0;
  text-align: right;
}

.button {
  width: 100%;
}

.iconText {
  @include font-primary-bold-SB4;
}

// TODO: These style overrides should removed once the Checkbox and Close icons are updated: https://griosf.atlassian.net/browse/RVLS-10573

.iconButton {
  svg g,
  polygon {
    fill: $pass;
  }

  &.disabled {
    svg g {
      fill: $fail;
    }
  }
}
