@import "~rivals/styles/colors.scss";

.link {
  color: $call-to-action-blue;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: $link-hover;
    transition: color 0.3s;
  }

  &.disabled {
    color: rgba($gray60, 60%);
    pointer-events: none;
    text-decoration: none;
  }
}
