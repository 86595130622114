@import "~rivals/styles/breakpoints";
@import "~rivals/styles/colors";
@import "~rivals/styles/typography.scss";
@import "~rivals/styles/variables";

.galleryImageContainer {
  cursor: pointer;
  overflow: hidden;

  &.animated {
    border-radius: 8px;
  }

  .descriptionContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: $spacing-12;
    text-align: center;

    .galleryName {
      @supports (-webkit-line-clamp: 3) {
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        line-height: 1.2;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      @include font-primary-bold-SB4;
      @include sizes-ending-with($screen-lg-mobile-max) {
        @include font-primary-bold-SB5;
        line-height: 1.2;
      }
    }

    .galleryDateCount {
      @include font-primary-body-B5;
      @include sizes-ending-with($screen-lg-mobile-max) {
        @include font-primary-body-B6;
      }
      align-items: center;
      column-gap: $spacing-4;
      display: flex;
    }
  }

  .animatedContainer {
    background-color: transparent;
    bottom: -12px;
    color: $white;
    left: 0;
    padding: $spacing-24 $spacing-12 $spacing-12 $spacing-12;
    position: absolute;
    right: 0;

    .galleryDateCount {
      @include font-yahoo-h5;
    }

    .galleryIcon {
      display: inline-block;
      height: 13px;
      margin-right: $spacing-12;
      width: 18px;
    }

    .galleryName {
      @include font-yahoo-h3;
      align-items: center;
      display: flex;

      svg {
        margin-right: $spacing-4;
      }
    }

    .gallerySummary {
      @include font-yahoo-h4;
    }
  }

  &:hover {
    .animatedContainer {
      background-image: linear-gradient(
        to bottom,
        transparent,
        rgba(0, 0, 0, 0.9)
      );
      bottom: 0;
      transition: bottom 0.3s linear, background-color 0.4s linear;
    }
  }

  .galleryImage {
    height: auto;
    width: 100%;

    img {
      aspect-ratio: 4/3;
      object-fit: cover;
    }
  }
}
