@import "~rivals/styles/breakpoints";
@import "~rivals/styles/colors";
@import "~rivals/styles/variables";

// TODO: https://griosf.atlassian.net/browse/RVLS-6703

$college-player-card-width-sm: 453px;

.cardContainer {
  align-items: center;
  background: $mine-shaft;
  display: flex;
  height: 100%;
  padding: 0 $spacing-24;

  &:hover {
    background-color: $river-bed;
  }

  &.socialCardVariant {
    height: calc(
      #{$college-social-card-height} - #{$college-social-card-header-height}
    );
    overflow: hidden;
    padding: 0 $college-social-card-internal-padding;
    pointer-events: none; // cancels hover effect from .cardContainer
    position: relative;
    @include sizes-ending-with($athlete-embed-card-min) {
      height: 100%;
      max-height: calc(#{$athlete-embed-card-height} - 22px);
      padding: 8px;
    }
  }

  /**
   * The following media query is based on the width of the Angular iframe containing
   * the card, not the width of the screen.
   * This will need to be refactored once React cards are rendered natively in React pages
   */
  @include sizes-ending-with($college-player-card-width-sm) {
    padding: 0 $spacing-12;
  }
}
