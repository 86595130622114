@import "~rivals/styles/typography";
@import "~rivals/styles/variables";

$light-gray: #bbbec1;

.container {
  @include font-yahoo-h5;
  background-color: #262626;
  color: $white;
  height: 100%;
  overflow: hidden;
  padding: $spacing-10;
  position: relative;
  text-transform: uppercase;
  width: 100%;

  .backgroundContainer {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    opacity: 0.1;
    position: absolute;
    width: 100%;
  }

  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    width: 100%;
    z-index: 1;
  }

  .row {
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  .thickRow {
    display: flex;
    flex: 1;
    justify-content: space-between;
  }

  .column {
    display: flex;
    flex-direction: column;
  }

  .lastName {
    @include font-yahoo-h3;
  }

  .starsAndRating {
    display: flex;
    justify-content: flex-end;
  }

  .rating {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: $spacing-4;
  }

  .rivalsRankIcon {
    background: url("/static/icons/icons_rivals_logo_white.svg") no-repeat;
    display: inline-block;
    height: 10px;
    width: 44px;
  }

  .prospectHeightWeightPosClass {
    @include font-yahoo-h6;
  }

  .breakLine {
    padding: 0 5px;
  }

  .prospect {
    align-items: flex-start;
    align-self: flex-end;
    display: flex;
    gap: $spacing-10;
  }

  .prospectImage {
    height: 85px;
  }

  .homeInformation {
    @include font-yahoo-h5;
  }

  .hometown {
    color: $light-gray;
  }

  .signingBlock {
    @include font-yahoo-h5;
    align-items: center;
    display: flex;
    gap: $spacing-4;
  }

  .commitSchoolLogo {
    height: 35px;
    width: 35px;
  }

  .commitDate {
    @include font-yahoo-h6;
    color: $light-gray;
  }

  .rankings {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .rank {
    align-items: center;
    display: flex;
    flex-direction: column;

    .type {
      @include font-yahoo-h6;
    }
  }

  .arrowbox {
    &.down,
    &.up {
      &::before {
        background-size: 6px;
        content: "";
        display: inline-block;
        height: 10px;
        left: -12px;
        position: absolute;
        top: 2px;
        width: 7px;
      }
    }

    &.down {
      &::before {
        background: url("/static/icons/icons_rank_decrease.svg") left no-repeat;
      }
    }

    &.up {
      &::before {
        background: url("/static/icons/icons_rank_increase.svg") left no-repeat;
      }
    }
  }
}
