@import "~rivals/styles/breakpoints.scss";
@import "~rivals/styles/variables.scss";

$small-star-size: 7px;
$default-star-size: 13px;

// TODO: https://griosf.atlassian.net/browse/RVLS-10530

.starIcon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: $default-star-size;
  display: block;
  float: left;
  height: $default-star-size;
  margin-right: $spacing-4;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  width: $default-star-size;

  &:last-child {
    margin-right: 0;
  }
}

.starsWrapperSmall {
  .starIcon {
    background-size: $small-star-size;
    height: $small-star-size;
    margin-right: $spacing-2;
    width: $small-star-size;

    &:last-child {
      margin-right: 0;
    }
  }
}

.starOff {
  background-image: url("/static/icons/icons_card_star_gray.svg");
}

.starOffBlack {
  background-image: url("/static/icons/icons_card_star_black_off_bordered.svg");
}

.starOnYellow {
  background-image: url("/static/icons/icons_card_star_yellow.svg");
}

.starOnBlue {
  background-image: url("/static/icons/icons_card_star_blue.svg");
}
