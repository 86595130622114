@import "~rivals/styles/breakpoints";
@import "~rivals/styles/colors";
@import "~rivals/styles/exports.module.scss";
@import "~rivals/styles/typography";
@import "~rivals/styles/variables";

.socialHeaderContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: $spacing-4 $college-social-card-internal-padding;
  @include sizes-ending-with($athlete-embed-card-min) {
    padding: $spacing-2 $spacing-4;
  }

  &.highSchool {
    background: linear-gradient(
      90deg,
      $rivals-primary 26.04%,
      $call-to-action-blue 100%
    );
  }

  &.college {
    background: linear-gradient(90deg, $tangaroa 30.21%, $rivals-primary 100%);
  }

  .profileType {
    text-transform: uppercase;
    @include font-yahoo-h25-secondary;
    @include sizes-ending-with($athlete-embed-card-min) {
      @include font-yahoo-h5;
    }
  }

  .logoContainer {
    align-items: center;
    display: flex;
  }

  svg {
    height: 15px;
    @include sizes-ending-with($athlete-embed-card-min) {
      height: 8px;
    }
  }
}
