@import "~rivals/styles/colors";
@import "~rivals/styles/mixins";
@import "~rivals/styles/typography";
@import "~rivals/styles/variables";
@import "~rivals/styles/exports.module.scss";

$labelHeight: 16px;

.eventCard {
  @include unselectable-text;
  display: block;
  height: 96px;
  position: relative;
  width: 260px;

  .dateHeader {
    @include font-primary-bold-SB7;
    color: $bone;
    height: 12px;
    line-height: 12px;
    padding-left: 3px;
    padding-top: $spacing-4;
    position: absolute;
  }

  .eventContent {
    display: grid;
    grid-template-columns: 150px 94px;
    padding: $spacing-16 $spacing-8 $spacing-8;
  }
}

.athlete {
  @include font-primary-body-B5;
  display: grid;
  grid-template-columns: 48px calc(100% - 48px);
  padding-top: $spacing-12;

  .athleteProfilePhoto {
    border-radius: 50%;
    height: 44px;
    padding: 2px;
    position: relative;
    width: 44px;
  }

  .athleteBioData {
    color: $bone;
    margin-top: -$spacing-4;

    .lastName {
      @include font-primary-bold-SB5;
      margin-bottom: $spacing-4;
      margin-top: -$spacing-4;
    }

    .position {
      @include font-primary-body-B7;
      display: flex;

      .positionAbbreviation {
        margin-right: $spacing-6;
      }

      .starsContainer {
        margin-top: $spacing-2;
      }
    }

    .longName {
      font-size: 11px;
      margin-top: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.event {
  background-color: $eye-black;
  height: 72px;
  width: 100%;

  .header {
    @include font-primary-bold-SB8;
    height: $labelHeight;
    line-height: $labelHeight;
    text-align: center;
    text-transform: uppercase;
  }

  .content {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: calc(100% - #{$labelHeight});
    padding: 2px;
  }

  .eventLabel {
    @include font-primary-bold-SB7;
    color: $bone;
  }

  .multiCollege {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.transferChevron {
  color: $bone;
  font-size: 10px;
  margin: 0 $spacing-4;
}

.withdrawnChevron {
  transform: rotate(180deg);
}

.eventIcon {
  bottom: -$spacing-4;
  position: absolute;
  right: 0;
}
