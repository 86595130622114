@import "~rivals/styles/breakpoints";
@import "~rivals/styles/colors";
@import "~rivals/styles/typography";
@import "~rivals/styles/variables";

.searchOverlay {
  background-color: $white;
  border-radius: $border-radius-8;
  box-shadow: 0 3px 2px 2px rgba(119, 127, 133, 0.3);
  height: auto;
  margin-top: 3px;
  max-height: calc(100vh - 80px);
  overflow-x: hidden;
  overflow-y: auto;
  padding: 20px;
  position: absolute;
  right: $spacing-24;
  top: 79px;
  width: 546px;
  z-index: 99999; // Required to keep SubscribeToday button & ads iframes behind overlay
  @include sizes-ending-with($screen-tablet-max) {
    right: 0;
    width: 100%;
  }

  .articlesResultsTitle,
  .playersResultsTitle {
    @include font-yahoo-b4;
    margin-bottom: $spacing-12;
  }

  .articlesResultsTitle {
    margin-top: 10px;
  }

  .playersResultsTitle {
    margin-top: $spacing-24;
  }
}

.searchArticleResults {
  margin: 0;
  padding: 0;

  .articleSnippet:not(:last-child) {
    margin-bottom: $spacing-12;
    @include sizes-ending-with($screen-tablet-max) {
      margin-bottom: $spacing-16;
    }
  }
}

.searchBox {
  height: 40px;

  input {
    padding: 0 12px;
  }
}

.searchBox::before {
  border-bottom: 20px solid $white;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  content: "";
  height: 0;
  position: absolute;
  right: 19px;
  top: -6px;
  width: 0;
}

.searchButton {
  background: url("/static/icons/icons_search_gray30.svg") no-repeat center/20px
    20px;
  border: none;
  bottom: 0;
  height: 20px;
  position: absolute;
  right: 25px;
  top: 30px;
  width: 20px;
}

.predictTextSection {
  border-bottom: 1px solid $gray50;
  display: block;
  padding: 10px 5px;
  width: 100%;
}

.predictText {
  background-color: $gray30;
  color: $white;
  margin: 2px;
  padding: 2px 8px;

  &:hover {
    color: $white;
  }
}

.predictionsContainer {
  display: flex;
  flex-wrap: wrap;
}

.spinnerWrapper {
  margin-top: $spacing-24;
}

.prospectCardWrapper {
  & > div {
    border-radius: $border-radius-8;
    margin: 0;
    overflow: hidden;
  }
}
