@import "~rivals/styles/breakpoints";
@import "~rivals/styles/colors";
@import "~rivals/styles/variables";

.container {
  color: $white;
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 100%;

  a {
    color: $white;

    &:hover {
      color: $white;
    }
  }

  &.socialMediaCard {
    height: $college-social-card-height;
    width: $college-social-card-width;

    * &:hover {
      color: $white;
    }
    @include sizes-ending-with($athlete-embed-card-min) {
      height: 100%;
      max-height: $athlete-embed-card-height;
      max-width: $athlete-embed-card-width;
      width: 100%;
    }
  }
}

.storybookContainer {
  & > a {
    position: static;
  }
}
