@import "~rivals/styles/breakpoints";
@import "~rivals/styles/colors";
@import "~rivals/styles/typography";
@import "~rivals/styles/variables";

.logoLine {
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
  margin-top: 12px;
  width: 55px;
  @include sizes-starting-with($screen-lg-mobile-min) {
    flex-direction: row;
    width: 125px;
  }
  @include sizes-ending-with($screen-lg-mobile-min) {
    margin-bottom: 20px;
    margin-left: 8px;
  }
}

.decimalRankNumber {
  height: 24px;
  text-align: center;
  width: 28px;
  @include font-yahoo-h3;
  @include sizes-ending-with($screen-lg-mobile-min) {
    margin-left: 12px;
    margin-top: -8px;
    order: 3;
  }
}

.desktopDesign {
  @include sizes-ending-with($screen-mobile-max) {
    display: none;
  }
}

.mobileDesign {
  @include sizes-starting-with($screen-lg-mobile-min) {
    display: none;
  }
}

.rankIconText {
  margin-left: 8px;
  @include sizes-ending-with($screen-lg-mobile-min) {
    margin-left: 0;
  }
}

.rivalsRankIcon {
  background: url("/static/icons/icons_rivals_logo_white.svg") no-repeat;
  display: inline-block;
  height: 10px;
  width: 44px;
}

.smallRankText {
  margin-top: -4px;
  @include font-yahoo-b5;
  @include sizes-starting-with($screen-lg-mobile-min) {
    margin-left: 8px;
  }
  @include sizes-starting-with($screen-tablet-min) {
    margin-left: 0;
  }
}

.container {
  padding-bottom: 8px;
}

.prospectListCard {
  background-color: #262626;
  height: $athlete-card-height-mobile;
  margin: 0 auto;
  max-width: 660px;
  position: relative;
  width: auto;
  @include sizes-starting-with($screen-lg-mobile-min) {
    height: $athlete-card-height;
  }

  a {
    color: $white;
  }

  a:hover {
    color: $gray85;
  }
}

.prospect {
  color: $white;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding: 8px;
  position: absolute;
  text-transform: uppercase;
  width: 100%;
}

.prospectImage {
  bottom: 8px;
  height: 150px;
  left: 8px;
  object-fit: cover;
  overflow: hidden;
  position: absolute;
  width: 103px;
}

.prospectRankings {
  height: 100%;
  position: absolute;
  right: 10px;
  text-align: center;
  width: 55px;
}

.arrowbox {
  background-size: 6px;
  display: inline-block;
  height: 10px;
  margin-bottom: 2px;
  margin-right: 4px;
  width: 7px;

  &.down {
    background: url("/static/icons/icons_rank_decrease.svg") left no-repeat;
  }

  &.up {
    background: url("/static/icons/icons_rank_increase.svg") left no-repeat;
  }
}

.rank {
  height: 44px;
  margin-bottom: 12px;
  width: 78px;

  .number {
    display: inline-block;
    width: auto;
    @include font-yahoo-h3;
  }

  .type {
    padding-bottom: 4px;
    @include font-yahoo-b5;
  }
}

.schoolLogoBox {
  height: $athlete-card-height-mobile;
  overflow: hidden;
  position: absolute;
  width: 100%;
  @include sizes-starting-with($screen-lg-mobile-min) {
    height: $athlete-card-height;
  }
}

.schoolLogoBg {
  left: 50%;
  opacity: 0.1;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);

  &.long-card {
    @include sizes-starting-with(390px) {
      margin-left: 10%;
    }
  }
}

.prospectCardRowOne {
  display: flex;
  flex-direction: column;
  order: 1;
  @include sizes-starting-with($screen-lg-mobile-min) {
    flex-direction: row;
    margin-left: 119px;
    margin-right: 65px;
  }
  @include sizes-starting-with($screen-tablet-min) {
    width: 100%;
  }

  .nameFlexBlock {
    order: 1;
    width: 175px;
    @include sizes-starting-with($screen-lg-mobile-min) {
      width: 35%;
    }

    .prospectName {
      order: 1;
      padding-bottom: -5px;
      padding-top: -5px;

      .firstName {
        overflow: hidden;
        padding-bottom: -4px;
        text-overflow: ellipsis;
        white-space: nowrap;
        @include font-yahoo-b3;
      }

      .lastName {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @include font-yahoo-h3;
      }
    }
  }

  .rankInfoFlexBlock {
    height: 16px;
    margin-top: 8px;
    order: 2;
    width: 110px;
    @include sizes-starting-with($screen-lg-mobile-min) {
      height: 50px;
      margin-left: 3%;
      margin-top: 0;
      width: 45%;
    }
    @include sizes-starting-with($screen-tablet-min) {
      margin-left: 0;
    }

    .prospectStars {
      height: 16px;
      padding-right: 7px;
      width: 112px;
      @include sizes-starting-with($screen-lg-mobile-min) {
        margin-bottom: -3px;
        margin-top: 0;
      }

      > span > i {
        background-size: 18px 12px;
        height: 12px;
        width: 18px;
        @include sizes-starting-with($screen-lg-mobile-min) {
          background-size: 19px 13px;
          height: 13px;
          width: 19px;
        }
      }
    }
  }
}

.prospectCardRowTwo {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  order: 2;
  @include sizes-starting-with($screen-lg-mobile-min) {
    flex-direction: row;
    margin-left: 119px;
    margin-right: 44px;
  }
  @include sizes-starting-with($screen-tablet-min) {
    width: 100%;
  }

  .commitment {
    margin-top: 8px;

    .commitInfo {
      display: inline-block;
      margin-left: 8px;
      order: 2;
      width: 100%;
      @include font-yahoo-h5;
      @include sizes-starting-with($screen-lg-mobile-min) {
        padding-top: 3px;
      }
      @include sizes-starting-with($screen-tablet-min) {
        margin-left: 0;
      }

      .commitDate {
        @include font-yahoo-b5;
      }

      .schoolName {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100px;
      }
    }

    .signingBlock {
      align-items: center;
      display: flex;
      flex-direction: row;
      padding-left: 114px;
      width: 100%;
      @include font-yahoo-b4;
      @include sizes-starting-with($screen-lg-mobile-min) {
        padding-left: 0;
        padding-top: 8%;
      }
      @include sizes-starting-with($screen-tablet-min) {
        gap: $spacing-8;
      }

      .commitSchoolLogo {
        max-height: 30px;
        max-width: 30px;
        order: 1;
      }
    }
  }

  .prospectInfo {
    display: flex;
    flex-direction: column;
    margin-left: 114px;
    width: calc(100% - 149px);
    @include font-yahoo-b5;
    @include sizes-starting-with($screen-lg-mobile-min) {
      margin-left: 0;
      width: 35%;
    }

    .classOf {
      margin-top: 8px;
      order: 3;
      @include sizes-starting-with($screen-lg-mobile-min) {
        margin-top: 12px;
      }
    }

    .homeInformation {
      margin-top: 8px;
      order: 2;
      width: 100%;
      @include sizes-starting-with($screen-lg-mobile-min) {
        margin-top: 12px;
      }

      .highschoolName,
      .hometown {
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @include sizes-starting-with($screen-tablet-min) {
          max-width: 150px;
        }
      }
    }

    .prospectHeightWeightPos {
      order: 1;
      padding-top: -5px;
      @include sizes-starting-with($screen-lg-mobile-min) {
        margin-top: -3px;
      }

      .breakLine {
        padding-left: 3px;
        padding-right: 3px;
      }
    }
  }
}

.linkWrapper:hover .prospectName {
  color: $gray85;
}
