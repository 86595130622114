@import "~rivals/styles/colors";
@import "~rivals/styles/mixins";
@import "~rivals/styles/typography";
@import "~rivals/styles/variables";
@import "~rivals/styles/exports.module.scss";
@import "~rivals/components/shared/Widgets/mixins";

.eventTickerWrapper {
  color: $abyss;
  overflow: hidden;
  @include sizes-starting-with($screen-sm-desktop-min) {
    margin-bottom: $spacing-24;
  }

  ul {
    margin-bottom: 0;
    padding: 0;

    li {
      display: inline-block;
    }
  }
}

.eventTicker {
  background-color: transparent;
  height: 98px;
  position: relative;
  width: 100%;
}

.emptyState {
  @include font-primary-bold-SB4;
  color: $bone-50;
  line-height: 80px;
  text-align: center;
  width: 100%;
}

.tickerDropdown {
  @include font-extended-extrabold-A5;
  display: flex;
  flex-direction: row;
  max-width: 320px;
  @include sizes-ending-with($screen-tablet-max) {
    justify-content: space-between;
    max-width: 100%;
  }

  :global {
    .ant-select,
    .ant-select-selector {
      padding-bottom: 7px;
      /* stylelint-disable-next-line declaration-no-important */
      padding-left: 0 !important;
    }

    .ant-select-arrow {
      align-items: unset;
      padding-bottom: 7px;
      top: 45%;
    }
  }

  .optionName {
    @include font-extended-headline-H4;
    color: $bone-10;
    text-transform: uppercase;
  }
}

.tickerSelector {
  order: 1;
  padding: 0;
  position: relative;
  text-transform: uppercase;
  top: 0;
}

.seeMoreButtonWrapper {
  @include font-primary-body-B5;
  color: $white;
  height: 40px;
  line-height: 30px;
  margin-left: $spacing-24;
  order: 2;

  &:hover {
    color: $white;
  }
}

.eventTypeSelectPopup {
  background-color: $bone-10;
  /* stylelint-disable-next-line declaration-no-important */
  width: 275px !important;

  :global {
    .ant-select-item.ant-select-item-option.ant-select-item-option-selected {
      background-color: $sand-30;
    }
  }

  .optionName {
    @include font-primary-medium-S5;
    text-transform: capitalize;
  }
}

.cardHolder {
  padding: 0 $spacing-4;
  position: relative;

  &::after {
    border-right: 1px solid rgba($clay, 0.5);
    bottom: 0;
    content: "";
    height: 66px;
    margin: auto 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 1px;
  }
}
