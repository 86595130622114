@import "~rivals/styles/breakpoints";
@import "~rivals/styles/mixins";
@import "~rivals/styles/variables";

.container {
  @include article-block-wrapper;
  display: flex;
  justify-content: center;
}

.wrapper {
  border-radius: $border-radius-8;
  height: $athlete-card-height;
  overflow: hidden;
  position: relative;
  width: $athlete-card-width;
  @include sizes-ending-with($screen-lg-mobile-max) {
    height: $athlete-card-height-mobile;
    width: 100%;
  }
}
