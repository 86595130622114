@import "~rivals/styles/breakpoints";
@import "~rivals/styles/colors";
@import "~rivals/styles/typography.scss";
@import "~rivals/styles/variables";

.modalContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  @include sizes-ending-with($screen-lg-mobile-max) {
    background-color: #24272a;
  }

  .content {
    flex: 1 1 auto;
    height: 100%;
    overflow: auto;

    .contentWrapper {
      height: 100%;
      position: relative;

      .captionBar {
        background-color: rgba(0, 0, 0, 0.5);
        bottom: 0;
        color: $white;
        height: auto;
        padding: 0 40px 40px;
        position: fixed;
        transition: ease-in-out 0.2s;
        width: 100vw;
        @include sizes-ending-with($screen-lg-mobile-max) {
          background-color: transparent;
          padding: 6px 20px 0 20px;
          position: relative;
        }

        .imageCount {
          @include font-yahoo-b4;
          padding: $spacing-12 0 $spacing-24 0;
        }
      }

      .blurredImage {
        overflow: hidden;
        position: relative;
      }

      img {
        box-shadow: 0 0 30px $black;
        display: block;
        margin: 0 auto;
        max-height: 91.5vh;
        max-width: 100%;
        opacity: 1;
        position: relative;

        &.desktop {
          @include sizes-starting-with($screen-lg-mobile-max) {
            display: none;
          }
        }

        &.mobile {
          @include sizes-ending-with($screen-lg-mobile-max) {
            display: none;
          }
        }
      }
    }

    .carouselButton {
      background-color: $card-gradient-dark;
      border-radius: 50%;
      height: 50px;
      opacity: 0.5;
      right: 40px;
      width: 50px;
      z-index: 1;
      @include sizes-ending-with($screen-lg-mobile-max) {
        top: 33vw;

        &.prevButton {
          top: 33vw;
        }
      }

      &:hover {
        opacity: 1;

        svg {
          circle {
            + g {
              fill: $white;
            }
          }
        }
      }

      svg {
        border-radius: 50%;

        circle {
          fill: $card-gradient-dark;

          + g {
            fill: #bbbec1;

            &:hover {
              fill: $white;
            }
          }
        }

        rect {
          fill: transparent;
        }
      }

      &.prevButton {
        left: 40px;
        transform: rotate(180deg);
      }
    }

    .imageGalleryWrapper {
      flex-grow: 1;
      height: 100%;
      margin: 0 auto;
      max-height: 91.5vh;
      overflow-y: auto;
      padding: 0 40px;

      .imageCount {
        color: $white;
        padding: 40px 0 20px 0;
        @include font-yahoo-b4;
      }

      .imagesContainer {
        margin: 0 auto;
        max-width: 960px;
        position: relative;

        .images {
          display: flex;
          flex-wrap: wrap;
          padding: $spacing-4 0;
          @include sizes-ending-with($screen-lg-mobile-max) {
            justify-content: flex-start;
          }

          .imageContainer {
            cursor: pointer;
            display: flex;
            flex-basis: 25%;
            padding: $spacing-4;
            position: relative;
            @include sizes-ending-with($screen-lg-mobile-max) {
              padding: 1px;
            }
            @include sizes-ending-with($screen-lg-mobile-max) {
              flex-basis: 33%;
            }

            .imageOverlay {
              bottom: 0;
              left: 0;
              position: absolute;
              right: 0;
              top: 0;

              &:hover {
                background: linear-gradient(
                  to bottom,
                  rgba(0, 0, 0, 0.3),
                  rgba(0, 0, 0, 0.3)
                );
              }
            }

            img {
              height: 100%;
              width: 100%;
            }
          }
        }
      }
    }
  }

  .footer {
    align-items: center;
    background: #000;
    display: flex;
    justify-content: space-between;
    padding: $spacing-12 $spacing-24;
    position: relative;
    @include sizes-starting-with($screen-lg-mobile-max) {
      display: none;
    }

    .toggleContainer {
      margin: 0;
    }
  }

  .header {
    @include font-yahoo-b4;
    background: #000;
    color: $white;
    column-gap: $spacing-24;
    display: flex;
    min-height: auto;
    padding: $spacing-12 $spacing-24 $spacing-12 $spacing-12;

    .actions {
      align-items: flex-start;
      display: flex;
      justify-content: flex-end;
      position: relative;

      .shareMenu {
        @include sizes-ending-with($screen-lg-mobile-max) {
          display: none;
        }
      }

      .toggleContainer {
        @include sizes-ending-with($screen-lg-mobile-max) {
          display: none;
        }
      }

      .actionIcon {
        background-color: $card-gradient-dark;
        border-radius: 50%;
        display: inline-block;
        fill: $gray60;
        height: 35px;
        padding: 9px;
        transition: ease-in-out 0.2s;
        width: 35px;
        z-index: 11;

        g {
          fill: #bbbec1;
        }

        &:hover {
          g {
            fill: $white;
          }
        }
      }
    }

    .info {
      display: flex;
      flex: 1;
      flex-direction: column;
      row-gap: $spacing-4;

      .name {
        @include font-yahoo-h3-secondary;
      }
    }
  }

  .toggleContainer {
    display: flex;
    margin: 0 $spacing-12;

    .galleryIcon,
    .imageIcon {
      align-items: center;
      background: $gray60;
      display: flex;
      justify-content: center;
      padding: 6px;
      width: 56px;

      a {
        margin: 0;
      }
    }

    .galleryIcon {
      background: url("/static/icons/gallery_toggle_bg_normal.svg") center
        no-repeat;
      border-bottom-left-radius: $spacing-24;
      border-top-left-radius: $spacing-24;

      .toggle {
        background: url("/static/icons/gallery_toggle_normal.svg") center
          no-repeat;
        height: 25px;
        width: 25px;
      }

      &.active {
        background: url("/static/icons/gallery_toggle_bg_active.svg") center
          no-repeat;

        .toggle {
          background: url("/static/icons/gallery_toggle_active.svg") center
            no-repeat;
        }
      }

      &:hover {
        a {
          background: url("/static/icons/gallery_toggle_hover.svg") center
            no-repeat;
        }
      }
    }

    .imageIcon {
      background: url("/static/icons/image_toggle_bg_normal.svg") center
        no-repeat;
      border-bottom-right-radius: $spacing-24;
      border-top-right-radius: $spacing-24;

      .imageToggle {
        background: url("/static/icons/image_toggle_normal.svg") center
          no-repeat;
        height: 20px;
        width: 20px;
      }

      &.active {
        background: url("/static/icons/image_toggle_bg_active.svg") center
          no-repeat;

        .imageToggle {
          background: url("/static/icons/image_toggle_active.svg") center
            no-repeat;
        }
      }

      &:hover {
        a {
          background: url("/static/icons/image_toggle_hover.svg") center
            no-repeat;
        }
      }
    }
  }

  :global {
    .ant-carousel {
      .slick-slider,
      .slick-list,
      .slick-track {
        height: 100%;
      }
    }
  }
}

.spinnerContainer {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.paywallContainer {
  background-color: $eye-black;
  border: 1px solid $hoops;
  margin: $spacing-12;
  opacity: 95%;
  padding: 0 $spacing-12;
  @include sizes-starting-with($screen-sm-desktop-min) {
    margin-left: auto;
    margin-right: auto;
    width: 900px;
  }
}

.overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 11; // make sure it's higher than share menu
}

.carousel {
  height: 100%;
}
